<template>
<div class="mb-14 mt-10">
  <OneNFT :name="item['name']" :img-data="item['image']" :token-id="item.tokenId" class="ml-8 mt-3 float-left" v-for="item in globalNFTs"></OneNFT>
<!--  <many-n-f-t class="ml-8 mt-3 float-left" v-for="item in [1,2,3,4,5,6,6,7,7,7,7,7,7,77,7,7,7,4]"></many-n-f-t>-->
  <div class="clear-both"></div>
</div>
</template>

<script>
import {mapState} from "vuex"
import OneNFT from "../components/NFTDisplay/oneNFT";
import ManyNFT from "../components/NFTDisplay/manyNFT";

export default {
  name: "HomePage",
  components:{
    ManyNFT,
    OneNFT
  },
  data(){
    return {

    }
  },
  computed:mapState(["totalSupply","connectTextContract","globalNFTs"]),
  methods:{
    parseData(data){
      const json = Buffer.from(data.substring(29), "base64").toString()
      console.log(json)
      return JSON.parse(json)
    }
  },
  async mounted() {
    if(this.totalSupply===0 || this.totalSupply===undefined || this.totalSupply===null){
      this.$store.state.totalSupply=(await this.connectTextContract.totalSupply()).toNumber()
    }
    console.log(this.totalSupply)
    // windows.alert(this.totalSupply)
    if(this.globalNFTs.length<this.totalSupply){
      console.log(this.totalSupply)
      // 如果globalNFTs的长度是0，那么就不停的加载globalNFT
      for(let i=1;i<=this.totalSupply;i++){
        console.log(i)
        let tokenURI=await this.connectTextContract.tokenURI(i)
        let result=this.parseData(tokenURI)
        console.log(result)
        result["tokenId"]=i
        let poemFrom=(await this.connectTextContract.poemFrom(i)).toNumber()
        result["poemFrom"]=poemFrom
        console.log(result)
        this.$store.state.globalNFTs.push(result)
      }
    }
  }
}
</script>

<style scoped>

</style>