<template>

  <div style="height: 17rem;width: 13rem" class="shadow-xl rounded overflow-hidden">

    <div class="stack" style="width: 100%">
      <img src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jinse.com%2F5571437_image3.png&refer=http%3A%2F%2Fimg.jinse.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653554744&t=258bdfbd52fc391dd77faeec6218359a" alt="Image 1" class="rounded" />
      <img src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jinse.com%2F5571437_image3.png&refer=http%3A%2F%2Fimg.jinse.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653554744&t=258bdfbd52fc391dd77faeec6218359a" alt="Image 2" class="rounded" />
      <img src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jinse.com%2F5571437_image3.png&refer=http%3A%2F%2Fimg.jinse.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653554744&t=258bdfbd52fc391dd77faeec6218359a" alt="Image 3" class="rounded" />
    </div>

    <div class="m-1.5">
      <div class="flex flex-row justify-between">
        <div class="font-sans text-xs subpixel-antialiased text-translate">author</div>
        <div class="font-sans text-xs subpixel-antialiased text-translate">#po 5</div>
      </div>
      <div class="mt-2 flex flex-row justify-end">
        <div class="font-sans subpixel-antialiased text-sm text-slate-500 mr-2"><i class="el-icon-apple"></i></div>
        <div class="font-sans subpixel-antialiased text-sm text-slate-500">52</div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "manyNFT"
}
</script>

<style scoped>

</style>